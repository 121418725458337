/// App custom styling starts here

app-root {
    @include extendElevation(0);
    height: 100%;
    display: block;
}

main {
    height: 100%;
}

[app-organiser] {
    overflow: hidden;
}

.pwa-alert {
    border-radius: 0;
    border: none;
    height: 0;
    max-height: 0;
    overflow: hidden;
    padding: env(safe-area-inset-top) 0 0 0;
    pointer-events: none;

    &.in {
        height: auto;
        max-height: calc(120px + env(safe-area-inset-top));
        transition: max-height 350ms ease-in;
        pointer-events: auto;
        flex-shrink: 0;
        flex-basis: auto;
    }
}

.pwa-banner {
    position: absolute;
    top: 100%;
    right: 8px;
    max-width: calc(100% - 16px);
    pointer-events: none;

    &.in {
        transform: translateY(calc(-100% - 8px));
        transition: transform 350ms ease-in;
        box-shadow: 0px 4px 16px 0px theme-color(--dark, 0.25);
        pointer-events: auto;
    }
}

app-support-article .style-article {
    margin-bottom: 64px;

    h4 {
        font-size: 24px;
        margin-top: 40px;
    }

    .img-responsive {
        margin-bottom: 36px;
        display: block;
        max-width: 100%;
        height: auto;
    }
}

.logo-text {
    font-size: 21px;
    font-weight: bold;
}

.logo {
    &.hp-logo {
        .manufacturer {
            max-width: 165px;
        }

    }
}

/// onetrust overwrites
/// wrapper used only to incease specificity
#onetrust-consent-sdk {

    #onetrust-banner-sdk,
    #onetrust-pc-sdk {
        font-size: 14px;
        h3, h4, h5 {
            font-size: 1em !important;
            font-weight: 500 !important;
        }
        @each $breakpoint, $map in map-get($modal, breakpoints) {
            @include breakpoint-from($breakpoint){
                border-radius: map-get($map, border-radius);
            }
        }
        .ot-pc-title {
            margin-top: 5px !important;
        }
    }

    button#onetrust-pc-btn-handler {
        background: none !important;
        color: theme-color(--primary) !important;
        border-color: inherit !important;
        &:hover {
            color: theme-color(--primary-20) !important;
        }
    }

    button:not([aria-expanded]) {
        background-color: theme-color(--primary) !important;
        border-color: theme-color(--primary) !important;
        border-radius: 4px !important;
        padding: 10px 16px !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        &:hover {
            background-color: theme-color(--primary-20) !important;
            border-color: theme-color(--primary-20) !important;
        }
    }

    .ot-always-active[class] {
        color: theme-color(--primary) !important;
    }

    #onetrust-pc-sdk .ot-active-menu[role="tab"] {
        border-color: theme-color(--primary) !important;
    }

    .ot-pc-footer {
        button {
            font-size: 14px !important;
            font-weight: 500 !important;
        }
        [class].ot-pc-footer-logo {
            display: none !important;
        }
    }

    #onetrust-pc-sdk *:focus,
    #onetrust-pc-sdk .ot-vlst-cntr > a:focus {
        outline: none !important;
    }

    #onetrust-policy-text,
    .ot-b-addl-desc,
    .ot-gv-list-handler,
    .ot-grp-desc { font-size: 1em !important; }

    #onetrust-pc-sdk {
        p,
        #ot-ven-lst .ot-ven-opts p,
        #ot-pc-desc,
        #ot-pc-title,
        .ot-li-title,
        .ot-sel-all-hdr span,
        #ot-host-lst .ot-host-info,
        #ot-fltr-modal #modal-header,
        .ot-checkbox label span,
        #ot-pc-lst #ot-sel-blk p,
        #ot-pc-lst .back-btn-handler p,
        #ot-pc-lst .ot-ven-name,
        #ot-pc-lst #ot-ven-lst .consent-category,
        .ot-leg-btn-container .ot-inactive-leg-btn,
        .ot-label-status,
        .ot-chkbox label span,
        #clear-filters-handler,
        .ot-optout-signal {
            color: theme-color(--shade-80) !important;
        }
    }
    #onetrust-policy-title,
    #onetrust-policy-text,
    .ot-b-addl-desc,
    .ot-dpd-desc,
    .ot-dpd-title,
    #onetrust-policy-text *:not(.onetrust-vendors-list-handler),
    .ot-dpd-desc *:not(.onetrust-vendors-list-handler),
    #onetrust-banner-sdk #banner-options *,
    #onetrust-banner-sdk .ot-cat-header {
        color: theme-color(--shade-80) !important;
    }
}